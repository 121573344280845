import React from "react";
import { Constants } from ".";

// Constants for TIMEOUT
export const TIMEOUT = 100000; // TimeOut en Miliseg (media hora: 1800000)
export const TIMEOUT_COUNTDOWN = 30; // CountDown en Segundos
export const TIMEOUT_TITLE = "Session Timeout";
export const TIMEOUT_MESSAGE_START = "The current session is about to expire in";
export const TIMEOUT_MESSAGE_END = "seconds.";
export const TIMEOUT_QUESTION = "Would you like to continue the session?";

// Constants for DOWNLOAD
export const tagsEmpty = "You are searching for ALL documents.";
export const resultsNotFound = "Sorry, we haven't found any results with your search criteria. Please, review tags and try again.";
export const noticeIos = "Notice for users of iOS systems: you must download files one by one. If multiple files are downloaded, it may not work on iOS";
export const addTags = "Add tag(s) or free text or filetype: and click on the search button. Empty means searching ALL documents";
export const doubleFileType = "More than one 'filetype' is banned!";
export const textAfteFileType = "Set 'fileType' between free text is not allowed.!";
export const spacesFiletype = "Not spaces between 'filetype:' and extension type 'pdf, pptx, docx, ...'";

export const resultsFound_ini = "We have found ";
export const resultsFound_fin = " results with your search criteria";

// ENVIROMENTS
export const localEnvironment = "local";
export const developEnvironment = "dev";
export const stagingEnvironment = "stg";
export const productionEnvironment = "prod";

export const styles = {
  dropzoneStyleError: {
    border: "2px solid #cc2929",
    backgroundColor: "rgba(204,41,41,0.15)"
  },
  dropzoneStyleActive: {
    backgroundColor: "rgba(184, 184, 184, 0.089)"
  },
  dropzoneStyle: {
    backgroundColor: "rgba(184, 184, 184, 0.5)"
  },
};

export const disclaimer = (
  <div id="tooltip">
    <span id="info">Info</span> <br />
    <span id="tooltiptext">
      Be aware that any personal data that you and others provide through this
      tool may be processed by Accenture PLC and/or its{" "}
      <a
        href="https://www.sec.gov/Archives/edgar/data/1467373/000146737318000318/acn831201810-kexhibit211.htm"
        target="blank"
      >
        affiliates
      </a>{" "}
      acquired companies (“Accenture”). The protection of your personal data is
      very important to Accenture. Accenture is committed to keeping your
      personal data secure, and processing it in accordance with, applicable
      data protection laws and our internal policies, including{" "}
      <a
        href="https://policies.accenture.com/policy/0090?rd=1&Country=United%20Kingdom"
        target="blank"
      >
        Accenture’s Global Data Privacy Policy 0090
      </a>{" "}
      processes your personal data to manage your relationship with Accenture,
      to communicate with you and manage Accenture’s business operations. Your
      decision to provide your personal data to Accenture is voluntary. However,
      given that this tool requires personal data to function, you will not be
      able to use this tool if you do not provide your personal data. Before
      providing any personal data through this tool, Accenture invites you to
      carefully read its{" "}
      <a
        href="https://in.accenture.com/protectingaccenture/data-security/5422-2/"
        target="blank"
      >
        privacy statement
      </a>{" "}
      includes important information on why and how Accenture is processing your
      personal data and explains your{" "}
      <a
        href="https://accentureinternal.service-now.com/support_portal?id=acn_service_catalog_dp&sys_id=479c620adbbf8b803c9a77e88c9619f1"
        target="blank"
      >
        individual rights
      </a>
      .
      Personal data includes information relating to natural persons who: can be identified 
      or who are identifiable, directly from the information in question; or who can be 
      indirectly identified from that information in combination with other information 
      (this list of identifiers-include name-identification-number location data- online identifier).
    </span>
    <br />
  </div>
);
export default Constants;