import SecureLS from 'secure-ls';
const securels = new SecureLS({
    encodingType: 'aes'
});

// securels.ls = {
//     setItem: (key, value) => {
//         sessionStorage.setItem(key, value)
//     },
//     getItem: (key) => sessionStorage.getItem(key),
//     removeItem: (key) => sessionStorage.removeItem(key),
//     get length() { return sessionStorage.length },
//     key: (i) => sessionStorage.key(i)

// }

export const sls = {
    set: (key, data) => {
        securels.set(key, data)
    },
    get: (key) => {
        return securels.get(key)
    },
    remove: (key) => {
        return securels.remove(key)
    },
    clear: () => securels.clear(),
    removeAll: () => securels.removeAll()
}

