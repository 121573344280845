import React from "react";
import './Header.css'
import { Container } from 'react-bootstrap';
import NavBar from "../../components/NavBar/NavBar";

class Header extends React.Component {
  render() {
    return (
      <Container className="Header" style={{"maxWidth": "100%", "paddingLeft": "0px", "paddingRight":"0px"}}>
        <div>
            <img src="/images/pixelt.png" alt="" width="1" height="10" border="0"/>
        </div>
        <div align="left">
          <NavBar />
        </div>
        <div className="Title">
            <img src="/images/title.png" alt="Spain ATC Inventory title" border="0"/>
        </div>
      </Container>
    );
  }
}

export default Header;
