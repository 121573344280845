import React from "react";
import ReactDOM from "react-dom";
import "./assets/styles/index.css";
import App from "./controllers/App/App.js";
import store from './store'
import { Provider } from 'react-redux'
import registerServiceWorker from "./services/registerServiceWorker";

ReactDOM.render(
    <Provider store={store}>
    <App />
  </Provider>, document.getElementById("root"));
registerServiceWorker();
