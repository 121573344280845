import * as types from '../types/Upload.types';

const initialState = {
  filenames: [],
  loadingTable: false,
  uploadingFile: false,
  message: {},
};

const upload = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
        case types.SET_FILE_NAME:
        return {...state, ...payload};
        case types.SET_JSON:
        return {...state, ...payload};
        case types.SET_FILE:
        return {...state, ...payload};
        case types.SET_LOADING_VALUE:
        return {...state, ...payload};
        case types.SET_MESSAGE:
          return {...state, ...payload};
        case types.SET_UPLOADING_FILES_VALUE:
          return {...state, ...payload};
    default:
      return state;
  }
};

export default upload;
