import React from 'react';
import qs from 'query-string';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { sls } from './secureStorage';
import apiMiddleware from '../api/api.middleware';
import appReducer from '../../state/reducers/index';
import { set_session_data as setSessionData } from '../../state/actions/Session.actions';
import { set_login_data as setLoginData } from '../../state/actions/Login.actions';

const withConfiguration = App => {
  return class extends React.Component {
    constructor(props) {
      super(props);

      const history = createBrowserHistory({
        basename: '',
      });

      let initialState = {};
      const slsState = sls.get('s');

      const middlewares = [routerMiddleware(history), thunk, apiMiddleware];

      initialState = { login: slsState.login };

      const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

      const store = createStore(
        appReducer(history),
        initialState,
        composeEnhancers(applyMiddleware(...middlewares)),
      );

      function setAccess_Token(access_token) {
        if (access_token) {
          store.dispatch(setLoginData({ access_token }));
          store.dispatch(setSessionData(true))
        }
      };
      if (window.location.hash) {
        const { access_token } = qs.parse(window.location.hash);

        if (access_token) {
          setAccess_Token(access_token);

        }

      }
      this.state = { store, history };
    }

    render() {
      const { store, history } = this.state;
      return (
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <App/>
          </ConnectedRouter>
        </Provider>
      );
    }
  };
};

export default withConfiguration;
