
import ApiService from './api.service'
import { API_CALL } from "./api.actions"
import { set_session_data } from '../../state/actions/Session.actions';


const createApiMiddleware = () =>
    ({ dispatch, getState }) => next => action => {
        switch (action.type) {
            case API_CALL:
                return handleApiCall(action, dispatch, getState);
            default:
                return next(action);
        }
    };

export default createApiMiddleware();

const handleApiCall = (action,dispatch, getState) => {
    const { uri, conf } = action.payload;
    const { access_token } = getState().login;
    return ApiService.apiCall(uri, conf, access_token)
        .then( result => {
            dispatch(set_session_data({spinner: false}))
            return Promise.resolve(result);
        })
        .catch( reason => {
           console.log(reason)
        return Promise.reject(reason)
        });
}