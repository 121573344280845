import React from "react";
import "./Admin.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import appConfig from '../../config/app-config.json';
import { downloadMetrics, updateTag, insertNewTag } from './Admin.actions';
import { Fetch } from '../../services/api';
import Spinner from "react-bootstrap/Spinner";
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import StorageIcon from '@material-ui/icons/Storage';

const initialState = {
  tagTo: ".NET",
  tagFrom: ".NET",
};

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;  
  }
    // Handler Master data inventory
    handleMasterDataInventory = () => {
      const appConfigEnt = appConfig[this.props.session.environment];
    
      const url = `${appConfigEnt.apiUri}/inventory/export/data/master?eid=` +
        this.props.session.user.eid;
      
      // TODO: API Consum and download the file
      this.props.downloadMetrics(url, this.props.session.user.apK);
    
      };

  // Handler Download Metrics
  handleDownloadMetrics = () => {
  const appConfigEnt = appConfig[this.props.session.environment];

  const url = `${appConfigEnt.apiUri}/inventory/export/download/metric?eid=` +
    this.props.session.user.eid;
  
  // TODO: API Consum and download the file
  this.props.downloadMetrics(url, this.props.session.user.apK);

  };

  // Handler Search Metrics
  handleSearchMetrics = () => {
    const appConfigEnt = appConfig[this.props.session.environment];
  
    const url = `${appConfigEnt.apiUri}/inventory/export/search/metric?eid=` +
      this.props.session.user.eid;
    
    // TODO: API Consum and download the file
    this.props.downloadMetrics(url, this.props.session.user.apK);
  
    };

    // Handler Search Metrics
    handleAccessMetrics = () => {
      const appConfigEnt = appConfig[this.props.session.environment];
    
      const url = `${appConfigEnt.apiUri}/inventory/export/access/metric?eid=` +
        this.props.session.user.eid;
      
      // TODO: API Consum and download the file
      this.props.downloadMetrics(url, this.props.session.user.apK);
    
      };

  setOptions = (options) => {
    let list = [];
    options.forEach((o) => {
      list.push(<option key={o}>{o}</option>);
    });
    return list;
  };

  validateUpdateTag = () => {
    const { tagTo, tagFrom} = this.state;

    if (tagTo === tagFrom) {
      this.notify("error", "Update tags - The tagFrom value is the same that tagTo");
    } else {
      this.handleUpdateTag();
    }
  };
  
  validateInsertNewTag = () => {
    const { newTag } = this.state;

    if (newTag === "") {
      this.notify("error", "Insert tags - The tag value can't be empty");
    } else {
      this.handleInsertTag();
    }
  };

  // Create Upload JSON, doing the Property Mapping to send to the REST API
  informNewTagJSON = (insertTagFileJSON) => {
    return insertTagFileJSON = {
      newTag: this.state.newTag.toUpperCase(),
      eid: this.props.session.user.eid,
      role: this.props.session.user.role,
      active: this.props.session.user.active
    };
  };
  
  // Create Upload JSON, doing the Property Mapping to send to the REST API
 informJSON = (updateTagFileJSON) => {
  return updateTagFileJSON = {
    tagFrom: this.state.tagFrom,
    tagTo: this.state.tagTo,
    eid: this.props.session.user.eid
  };
};

  // Handler update tag
  handleUpdateTag = () => {
    const appConfigEnt = appConfig[this.props.session.environment];
  
    const url = `${appConfigEnt.apiUri}/inventory/tag/update`;

    let uploadTagJSON;
    uploadTagJSON = this.informJSON(uploadTagJSON);
    this.props.updateTag(url, this.props.session.user.apK, uploadTagJSON);

    };
    
  // Handler insert tag
  handleInsertTag = () => {
    const appConfigEnt = appConfig[this.props.session.environment];
  
    const url = `${appConfigEnt.apiUri}/inventory/tag/insert`;

    let uploadTagJSON;
    uploadTagJSON = this.informNewTagJSON(uploadTagJSON);
    this.props.insertNewTag(url, this.props.session.user.apK, uploadTagJSON);

    };

  //Toasts
  notify = (type, msg) => toast[type]((type === "error" ? "" : " ") + msg);

  render() {
    const { message, session } = this.props;

    // Messages
    if (typeof message.type !== 'undefined') {
      this.notify(message.type, message.msg);
    }

    return (
      <div className="Admin">
      <div>
        <img src="/images/pixelt.png" alt="" width="1" height="25" border="0"/>
      </div>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/*Exports*/}
          <h2>Exports</h2>
          <Tooltip title="Export items downloaded by users">
            <IconButton className= "iconExportButton" style={{"color": "white"}} aria-label="download" onClick={(event) => this.handleDownloadMetrics()}>
              <GetAppIcon style={{ color: 'white' }}/>&nbsp;
              Download metrics
            </IconButton>
          </Tooltip>
          <Tooltip title="Export searches made by users">
            <IconButton className= "iconExportButton" style={{"color": "white"}} aria-label="search" onClick={(event) => this.handleSearchMetrics()}>
              <SearchIcon style={{ color: 'white' }} />&nbsp;
              Search metrics
            </IconButton>
          </Tooltip>
          <Tooltip title="Export accesses to the tool">
            <IconButton className= "iconExportButton" style={{"color": "white"}} aria-label="access" onClick={(event) => this.handleAccessMetrics()}>
                <VpnKeyIcon style={{ color: 'white' }} />&nbsp;
                Access metrics
            </IconButton>
          </Tooltip>
          <Tooltip title="Export all data from master table">
            <IconButton className= "iconExportButton" style={{"color": "white"}} aria-label="data" onClick={(event) => this.handleMasterDataInventory()}>
              <StorageIcon style={{ color: 'white' }} />&nbsp;
              Export master data Inventory
            </IconButton>
          </Tooltip>
        </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <br/>
      <br/>
      <br/>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/*Tag from (Select)*/}
          <h2>Update tags</h2>
        </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/*Tag from (Select)*/}
          <h3>Tag from</h3>
          <select
            className="select"
            id="tagFrom"
            onChange={(event) =>
              this.setState({ tagFrom: event.target.value })
            }
            >
            {this.setOptions(session.listActualTags)}
          </select>
          <br/>
        </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/*Tag to (Select)*/}
          <h3>Tag to</h3>
          <Fetch path={'/'} options="tags">
            {({ data, loading, error }) => {
                if (error) {
                    return <p>{error.message}</p>;
                }
                if (loading) {
                    return <Spinner animation="border" style={{color: "white"}}></Spinner>;
                }
                if (data) {
                  return <select
                    className="select"
                    id="tagTo"
                    onChange={(event) =>
                      this.setState({ tagTo: event.target.value })
                    }
                  >
                    {this.setOptions(data)}
                  </select>;
              }
                return <Spinner animation="border" style={{color: "white"}}></Spinner>;
            }}
          </Fetch>
        </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/*Submit*/}
          <button id="botonUpdateTag" onClick={() => this.validateUpdateTag()}>Update tags</button>
        </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <br/>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/*Insert new tag*/}
          <h3>New tag</h3>
          <input
            type="text"
            id="newTag"
    //                className={titleClass}
    //                onKeyUp="upperCase(this.id)"
            onChange={event => this.setState({ newTag: event.target.value })}
          />
        </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/*Submit*/}
          <button id="botonInsertNewTag" onClick={() => this.validateInsertNewTag()}>Insert new tag</button>
          </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      </div>
    );
  }
}
const mapDispatchToProps = {
  downloadMetrics, updateTag, insertNewTag
}

const mapStateToProps = state => {
  return { session: state.session,
          filename: state.download.fileName, 
          message: state.download.message,
        }
}
export default connect(mapStateToProps, mapDispatchToProps)(Admin);