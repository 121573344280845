import axios from "axios";



const apiCall = (uri, conf, token) => {

    const callConf = {
        ...conf,
        headers: { ...conf.headers, ...{Authorization: 'Bearer ' + token} },
        data: {...conf.data}
    };
    const builtUri = buildUri(uri);

    axios.interceptors.response.use( response => response, error => error.response ? Promise.resolve(error.response) : Promise.reject(error) )

    return axios({
        url: builtUri.slice(1),
        ...callConf,
    }).then(
            response  => response.status===200 || response.status===308 ? Promise.resolve(response.data) : Promise.reject(checkResponseError(response)), 
            ()  => Promise.reject(reasons.GENERIC_ERROR) )
};

const getUri = uri => uri.startsWith('/') ? uri : '/' + uri;

// builds the uri
let buildUri = (uri) => {
    return getUri(uri);
}

const checkResponseError = response => {
    switch (response.status) {
        case 206:
            return reasons.PARTIAL_CONTENT;
        case 302:
            return reasons.ACCESS_DENIED; //MALO 302 Found movido temporalmente a Location
        case 308:
            return reasons.ABORTED_REQUEST; //MALO 308 Permanent Redirect movido a Location
        case 400:
            return reasons.BAD_REQUEST;
        case 401:
            return reasons.INVALID_CLIENT;
        case 403:
            return reasons.SERVICE_BLOCKED;
        case 404:
            return reasons.PRODUCT_UNKNOWN;
        case 405:
            return reasons.CANCELATION_INVALID;
        case 419:
            return reasons.TIMEOUT;
        case 500:
            return reasons.SERVER_ERROR;
        default:
            return reasons.GENERIC_ERROR;
    }
};

export const reasons = {
    PARTIAL_CONTENT: 'partialContent', // 206
    ACCESS_DENIED: 'accessDenied',
    ABORTED_REQUEST: 'abortedRequest', // 308
    UNAUTHORIZED_CLIENT: 'unauthorizedClient',
    INVALID_CLIENT: 'invalidClient',
    SERVICE_BLOCKED: 'serviceBlocked',
    PRODUCT_UNKNOWN: 'productUnknown',
    CANCELATION_INVALID: 'cancelationInvalid',
    TIMEOUT: 'timeout',
    LOGOUT: 'logout',
    GENERIC_ERROR: 'generic error',
    BAD_REQUEST: 'badRequest',
    SERVER_ERROR: 'internalServerError',
};

export default {
    apiCall
}