import { combineReducers } from 'redux'
import download from './Download.reducer';
import upload from './Upload.reducer';
import { connectRouter } from 'connected-react-router';
import session from './Session.reducer';
import login from './Login.reducer';
import admin from './Admin.reducer';


export default (history) => combineReducers({
    router: connectRouter(history),
    download: download,
    session: session,
    login: login,
    admin: admin,
    upload: upload,
});