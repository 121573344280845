import { SET_SESSION_DATA} from '../types/Session.types';

const initialState = {
  isLoggedIn: false,
};

const session = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
        case SET_SESSION_DATA:
          return Object.assign({}, state, {
                  isLoggedIn: action.payload
                })
    default:
      return state;
  }
};

export default session;
