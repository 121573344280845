import { CognitoAuth } from 'amazon-cognito-auth-js/dist/amazon-cognito-auth'
import { CognitoUserPool } from 'amazon-cognito-identity-js'
import { config as AWSConfig } from 'aws-sdk'
import appConfig from '../config/app-config.json'

AWSConfig.region = appConfig.region

// Creates a CognitoAuth instance
const createCognitoAuth = () => {
  const appWebDomain = appConfig.userPoolBaseUri.replace('https://', '').replace('http://', '')
  const auth = new CognitoAuth({
    UserPoolId: appConfig.userPool,
    ClientId: appConfig.clientId,
    AppWebDomain: appWebDomain,
    TokenScopesArray: appConfig.tokenScopes,
    RedirectUriSignIn: appConfig.callbackUri,
    RedirectUriSignOut: appConfig.signoutUri
  })
  return auth
}

// Creates a CognitoUser instance
const createCognitoUser = () => {
  const pool = createCognitoUserPool()
  return pool.getCurrentUser()
}

// Creates a CognitoUserPool instance
const createCognitoUserPool = () => new CognitoUserPool({
  UserPoolId: appConfig.userPool,
  ClientId: appConfig.clientId
})

// Get the URI of the hosted signining Cognito user pool in screen
const getCognitoSignInUriCog = (environment) => {
  const appConfigEnt = appConfig[environment];
  const signinUri = `${appConfigEnt.userPoolBaseUri}/login?response_type=token&client_id=${appConfigEnt.clientId}&redirect_uri=${appConfigEnt.callbackUri}`
  return signinUri
}
 
// Get the URI of federation log in screen
const getCognitoSignInUriFed = (environment) => {
  const appConfigEnt = appConfig[environment];
  const signinUri = `${appConfigEnt.userPoolBaseUri}/authorize?idp_identifier=${appConfigEnt.idpIdentifier}&response_type=token&client_id=${appConfigEnt.clientId}&redirect_uri=${appConfigEnt.callbackUri}`
  return signinUri
}

// Get the URI of the hosted signining Cognito user pool in screen to logout
const getCognitoSignOutUriCog = (environment) => {
  const appConfigEnt = appConfig[environment];
  const signinUri = `${appConfigEnt.userPoolBaseUri}/logout?client_id=${appConfigEnt.clientId}&logout_uri=${appConfigEnt.signoutUri}`
  return signinUri
}

// Get the URI of the hosted signining Cognito user pool in screen to logout
const getCognitoSignOutUriFed = (environment) => {
  const appConfigEnt = appConfig[environment];
  const signinUri = `${appConfigEnt.userPoolBaseUri}/logout?client_id=${appConfigEnt.clientId}&redirect_uri=${appConfigEnt.callbackUri}&state=STATE&response_type=token&scope=email+openid+aws.cognito.signin.user.admin`
  return signinUri
}


// Parse the response from a Cognito callback URI (assumed a token or code is in the supplied href). Returns a promise.
const parseCognitoWebResponse = (href) => {
  return new Promise((resolve, reject) => {
    const auth = createCognitoAuth()

    // userHandler will trigger the promise
    auth.userhandler = {
      onSuccess: function (result) {
        resolve(result)
      },
      onFailure: function (err) {
        reject(new Error('Failure parsing Cognito web response: ' + err))
      }
    }
    auth.parseCognitoWebResponse(href)
  })
}

// Gets a new Cognito session. Returns a promise.
const getCognitoSession = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = createCognitoUser()
    cognitoUser.getSession((err, result) => {
      if (err || !result) {
        reject(new Error('Failure getting Cognito session: ' + err))
        return
      }

      // Resolve the promise with the session credentials
      console.debug('Successfully got session: ' + JSON.stringify(result))

      const session = {
        credentials: {
          accessToken: result.accessToken.jwtToken,
          idToken: result.idToken.jwtToken,
          refreshToken: result.refreshToken.token
        },
        user: {
          userName: result.idToken.payload['cognito:username'],
          email: result.idToken.payload.email,
          role: "",
          active: false
        }
      }
            resolve(session)
    })
  })
}

// Sign out of the current session (will redirect to signout URI)
const signOutCognitoSession = () => {
  const auth = createCognitoAuth()
  auth.signOut()
}

export default {
  createCognitoAuth,
  createCognitoUser,
  createCognitoUserPool,
  getCognitoSession,
  getCognitoSignInUriCog,
  getCognitoSignInUriFed,
  getCognitoSignOutUriCog,
  getCognitoSignOutUriFed,
  parseCognitoWebResponse,
  signOutCognitoSession
}
