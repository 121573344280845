import { api_call } from '../../core/api/api.actions';
import * as types from '../types/Admin.types';
//import appConfig from '../../config/app-config.json';
import Axios from "axios";
     
export const set_message = (message) => ({
  type: types.SET_LOADING_VALUE,
  payload: { message },
});

export const set_downloading_files = (downloadingFile) => ({
  type: types.SET_DOWNLOADING_FILES_VALUE,
  payload: { downloadingFile },
});

export const set_updating_tag= (updatingTag) => ({
  type: types.SET_UPDATING_TAG_VALUE,
  payload: { updatingTag },
});

export const set_inserting_new_tag= (insertingNewTag) => ({
  type: types.SET_INSERTING_NEW_TAG_VALUE,
  payload: { insertingNewTag },
});

export const insert_new_tag = (url, apK, json) => dispatch => {
  dispatch(set_inserting_new_tag(true));
  return dispatch(
    api_call(url, {
      method: 'PUT',
      responseType: 'json',
      headers: {
        Authorization: 'validToken',
        'Content-Type': 'application/json',
        'x-api-key': apK,
      },
      data:json,
    }),
  ).then(newTagInserted => {
      let message = newTagInserted.outputMessage;
      if (message.includes(json.eid)) {
        dispatch(set_message({ type: "success", msg: message}));
        dispatch(set_message({}));
        dispatch(set_inserting_new_tag(false));
      } else {
        dispatch(set_message({ type: "error", msg: message}));
        dispatch(set_message({}));
        dispatch(set_inserting_new_tag(false));
      }
    }
  ).catch(err => {
    console.log(err);
    dispatch(set_message({ type: "error", msg: "Something was wrong. Please, try again!"}));
    dispatch(set_message({}));
    dispatch(set_inserting_new_tag(false));
  });
}

export const update_tag = (url, apK, json) => dispatch => {
  //const appConfigEnt = appConfig[environment];
  dispatch(set_updating_tag(true));
  return dispatch(
    api_call(url, {
      method: 'PUT',
      responseType: 'json',
      headers: {
        Authorization: 'validToken',
        'Content-Type': 'application/json',
        'x-api-key': apK,
      },
      data:json,
    }),
  ).then(tagUpdated => {
      let message = tagUpdated.outputMessage;
      dispatch(set_message({ type: "success", msg: message}));
      dispatch(set_message({}));
      dispatch(set_updating_tag(false));
    }
  ).catch(err => {
    console.log(err);
    dispatch(set_message({ type: "error", msg: "Something was wrong. Please, try again!"}));
    dispatch(set_message({}));
    dispatch(set_updating_tag(false));
  });
}

export const download_metrics = (url, apK) => dispatch => {
  //const appConfigEnt = appConfig[environment];
  dispatch(set_downloading_files(true));
  return dispatch(
    api_call(url, {
      method: 'GET',
      responseType: 'json',
      headers: {
        Authorization: 'validToken',
        'Content-Type': 'application/json',
        "x-api-key": apK,
      },
    }),
  ).then(metricsToDownload => {
    if (metricsToDownload.outputMessage==="") {
      //window.open(metricsToDownload.metricExportFileUrl)
      //dispatch(set_message({ type: "success", msg: "Downloading file(s)…"}));
      //dispatch(set_message({}));

      let metricExportFileUrl = metricsToDownload.metricExportFileUrl;
      let fileName = metricsToDownload.fileName;
  
      Axios.get(metricExportFileUrl, 
        { method: 'GET',
          responseType: 'blob',
          headers: {},
        }
      ).then(res => {
        let blob = new Blob([res.data]);
        const downloadUrl = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      }).catch(err => 
        {
          console.log(err);
          dispatch(set_message({ type: "error", msg: "Something was wrong. Please, try again!"}));
          dispatch(set_message({}));
          dispatch(set_downloading_files(false));
        });
      dispatch(set_message({ type: "success", msg: "Downloading file(s)…"}));
      dispatch(set_message({}));
      dispatch(set_downloading_files(false));
    } else {
      dispatch(set_message({ type: "error", msg: metricsToDownload.outputMessage }));
      dispatch(set_message({}));
      dispatch(set_downloading_files(false));
    }
    }
  ).catch(err => {
    console.log(err);
    dispatch(set_message({ type: "error", msg: "Something was wrong. Please, try again!"}));
    dispatch(set_message({}));
  });
}