import React from "react";
import * as PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
  Slide
} from "@material-ui/core";
import clsx from "clsx";
import red from "@material-ui/core/colors/red";
import * as Constants from "../Constants";

const useStyles = makeStyles((theme) => ({
  dialog: {
    'font-family': 'Graphik Web',
    borderRadius: 0,
    backgroundColor: '#E0E0E0',
  },
  button: {
    'font-family': 'Graphik Web',
    borderRadius: 0,
    textTransform: "none",
    padding: 5,
    border: 'thin solid #A100FF',
    color: 'rgb(0, 0, 0)',
    backgroundColor: '#F7F5F5',
    "&:hover": {
      backgroundColor: '#6C757D',
    },
    "&:active": {
      color: '#F7F5F5',
      backgroundColor: '#343A40',
    },
},
  logout: {
    'font-family': 'Graphik Web',
    border: 'thin solid #A100FF',
    color: 'rgb(0, 0, 0)',
    backgroundColor: '#F7F5F5',
    "&:hover": {
      backgroundColor: '#6C757D',
    },
    "&:active": {
      color: '#F7F5F5',
      backgroundColor: '#343A40',
    },
  },
  countdown: {
    color: red[700]
  },
  title: {
    'font-family': 'Graphik Web',
  },
  message: {
    "font-family": "Graphik Web",
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SessionTimeoutDialog({
  open,
  countdown,
  onLogout,
  onContinue
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      aria-labelledby="session-timeout-dialog"
      aria-describedby="session-timeout-dialog"
      classes={{ paper: classes.dialog }}
      TransitionComponent={Transition}
    >
      <DialogTitle id="session-timeout-dialog-title" >
        <div className={classes.title}>{Constants.TIMEOUT_TITLE}</div>
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.message}>
          {Constants.TIMEOUT_MESSAGE_START}{" "}
          <span className={classes.countdown}>{countdown}</span> {Constants.TIMEOUT_MESSAGE_END}
        </Typography>
        <Typography className={classes.message}>{Constants.TIMEOUT_QUESTION}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onLogout}
          variant="contained"
          className={clsx(classes.logout, classes.button)}
        >
          Logout
        </Button>
        <Button
          onClick={onContinue}
          color="primary"
          variant="contained"
          className={classes.button}
        >
          Continue Session
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SessionTimeoutDialog.propTypes = {
  /**
   * indicator whether the dialog is open/close
   */
  open: PropTypes.bool.isRequired,
  /**
   * the countdown timer.
   */
  countdown: PropTypes.number.isRequired,
  /**
   * callback function to handle closing action
   */
  onLogout: PropTypes.func.isRequired,
  /**
   * callback function to handle confirm action.
   */
  onContinue: PropTypes.func.isRequired
};
