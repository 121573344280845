import { SET_SESSION_DATA, SET_SESSION } from '../types/Session.types';

export const set_step = (step) => ({
    type: SET_SESSION,
    payload: step
});

const set_data = (data) => ({
    type: SET_SESSION_DATA,
    payload: data
});

export const set_session_data = (data) =>
    dispatch => {
        dispatch(set_data(data));
    }

    
