import React, { useRef, useState, useEffect, useCallback } from 'react';

const PreViewer = ({ src }) => {
  const iframeRef = useRef(null);
  const [iframeSrc, setIframeSrc] = useState('about:blank');
  const [retryCount, setRetryCount] = useState(0);
  const [hasLoaded, setHasLoaded] = useState(false); // Track successful load
  const maxRetries = 5;
  const retryInterval = 5000; // 5 seconds
  const resetInterval = 100; // 100 ms for about:blank reset

  const loadIframe = useCallback(() => {
    // Reset hasLoaded to false to handle new src
    setHasLoaded(false);

    // Apply reset for about:blank
    if (retryCount > 0) {
      //console.log('Setting iframeSrc to: about:blank for reset');
      setIframeSrc('about:blank');
      setTimeout(() => {
        const newSrc = `https://docs.google.com/gview?url=${encodeURIComponent(src)}&embedded=true`;
        //console.log('Setting iframeSrc to:', newSrc);
        setIframeSrc(newSrc);
      }, resetInterval);
    } else {
      const newSrc = `https://docs.google.com/gview?url=${encodeURIComponent(src)}&embedded=true`;
      //console.log('Setting iframeSrc to:', newSrc);
      setIframeSrc(newSrc);
    }
  }, [src, retryCount]);

  const handleLoad = () => {
    //console.log('Iframe loaded with src:', iframeSrc);
    if (iframeSrc !== 'about:blank') {
      setHasLoaded(true); // Mark as loaded successfully
      setRetryCount(0); // Reset retry count after a successful load
    }
  };

  useEffect(() => {
    setRetryCount(0); // Reset retry count on src change
    loadIframe(); // Load iframe with new src
  }, [src, loadIframe]);

  useEffect(() => {
    if (hasLoaded || retryCount >= maxRetries) return; // Stop reattempting if loaded or max retries reached

    const retryTimeout = setTimeout(() => {
      //console.log('Retrying iframe load:', retryCount + 1);
      setRetryCount((prevCount) => prevCount + 1);
      loadIframe(); // Attempt to reload iframe
    }, retryInterval);

    return () => clearTimeout(retryTimeout);
  }, [retryCount, hasLoaded, loadIframe]);

  return (
    <iframe
      ref={iframeRef}
      src={iframeSrc}
      style={{ width: '100%', height: '100%', border: 'none' }}
      title="Document Viewer"
      onLoad={handleLoad}
    />
  );
};

export default PreViewer;