import React from 'react';

class Resize extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            width: window.innerWidth, 
            height: window.innerHeight 
        };
    }
  
  render() {
      //this.props.changeResize(this.state.width, this.state.height);
    //return this.props.changeResize(this.state.width, this.state.height);
    return null
  }
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    this.props.changeResize(this.state.width, this.state.height);
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
} export default Resize;
