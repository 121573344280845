import React from "react";
import './Footer.css';
import {Container } from 'react-bootstrap';

class Footer extends React.Component {
  render() {
    return (
        <Container className="Footer" style={{"textAlign": "center"}}>
          <a className="link-container-small" href="mailto:spainatc.comms@accenture.com?subject=Inventory%20-%20Suggestion">Any question or suggestion?</a> - <a className="link-container-small" href="mailto:spainatc.comms@accenture.com?subject=Inventory%20-%20Data%20Correction">Contact us for Data Correction Purposes</a>
          <p className="link-container-tiny">© 2024 Accenture. Accenture Highly Confidential. For internal use only.</p>
          <div>
            <img src="/images/pixelt.png" alt="" width="1" height="20" border="0"/>
          </div>
        </Container>
        
      );
  }
}

export default Footer;