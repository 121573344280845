import { LOGIN_DONE, SET_LOGGEDIN_TO_FALSE, SET_LOGIN_DATA } from "../types/Login.types";

const initialState = {
    access_token: null,
};

const login = (state = initialState, action) => {
    const { type , payload } = action;
    switch (type) {
        case LOGIN_DONE:
            return { ...state, ...{ loggedIn: true } };
        case SET_LOGGEDIN_TO_FALSE:
            return { ...state, ...{ loggedIn: false } };
        case SET_LOGIN_DATA:
                return {...state, ...payload};
        default:
            return state;
    }
}

export default login;