import React from "react";
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import "./ValueTags.css";

const StyleChip = withStyles({
  root: {
    'font-family': 'Graphik Web',
    width: 'fit-content',
    'border-radius': '0px',
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    cursor: "pointer",
    backgroundColor: '#F7F5F5',
    border: 0,
    //border: 'thin solid #A100FF',
  }
})(Chip);

var listTopPills = [];

export default class ValueTags extends React.Component {
constructor(props) {
    super(props);
    listTopPills = this.props.pills;
}

render() {
  //console.log(listTopPills);
  let chips = [];
  for (let index=0; index < listTopPills.length; index++) {
    let chipActual = "Chip"+index;
    chips.push(
      <StyleChip
        key={chipActual}
        ref={chipActual}
        size="small"
        label={listTopPills[index]}
        onClick={() => {
          if (this.refs[`${chipActual}`].style.opacity !== "0") {
            this.props.handleDelete(this.refs[`${chipActual}`].innerText.toUpperCase());
            this.refs[`${chipActual}`].style.opacity = "0";
          }
        }}
        onDelete={() => {
          if (this.refs[`${chipActual}`].style.opacity !== "0") {
            this.props.handleDelete(this.refs[`${chipActual}`].innerText.toUpperCase());
            this.refs[`${chipActual}`].style.opacity = "0";
          }
        }}
        deleteIcon={<img alt={listTopPills[index]} src="/images/icons/loupe.svg" height="18" border="0"/>}
      />
    );
    chips.push(
      <img key={index} src="/images/pixelt.png" alt="" width="4" height="1" border="0"/>
    );
  }

  return ( 
    <div className="ValueTags">
      {chips}
    </div>
    );
  }
}