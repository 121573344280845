import React from "react";
import "./Administration.css";
import { connect } from 'react-redux'
import { Admin } from "../../components";
import Resize from "../../components/Resize/Resize";
import { Container } from 'react-bootstrap';
import Spinner from "react-bootstrap/Spinner";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import LoadingOverlay from 'react-loading-overlay';

const mapStateToProps = state => {
  return { session: state.session, downloadingFile: state.admin.downloadingFile, updatingTag: state.admin.updatingTag, insertingNewTag: state.admin.insertingNewTag,}
}

class Administration extends React.Component {
  state = {
    heightAval: window.innerHeight-847,
    downloadingFile: false,
    updatingTag: false,
    insertingNewTag: false
  };

  // Handler Window's Resize
  handleResize = (width, height) => {
    const heightAval=height-847;
    this.setState({
      heightAval 
    });
  };

  componentDidMount () {
    // If it's not logged in, redirect to Home
    if (!this.props.session.isLoggedIn) window.location.replace("/");
  }

  render() {
    const { session } = this.props;
    const { heightAval } = this.state;
    const { downloadingFile } = this.props;
    const { updatingTag } = this.props;
    const { insertingNewTag } = this.props;
    LoadingOverlay.propTypes = undefined;
    
    return (
      <LoadingOverlay active={insertingNewTag} text={"Inserting new tag in data..."} spinner>
      <LoadingOverlay active={updatingTag} text={"Updating tagFrom to tagTo in data..."} spinner>
      <LoadingOverlay active={downloadingFile} text={"Preparing file to download..."} spinner>
      <div className="Administration">
        <Resize changeResize={this.handleResize}/>
        {session.isLoggedIn && (
          <Container className="container-lg" style={{"maxWidth": "85%"}}>
            <Header />
            <Admin/>
            <div>
              <img src="/images/pixelt.png" alt="" width="1" height={heightAval} border="0"/>
            </div>
            <Footer /> 
          </Container>
        )}
        {!session.isLoggedIn && (
          <Spinner animation="border" style={{color: "white"}}></Spinner>
        )}
    </div>
    </LoadingOverlay>
    </LoadingOverlay>
    </LoadingOverlay>
    );
  }
}

export default connect(mapStateToProps)(Administration)