import React from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import './NavBar.css'
import { Row, Col, Container } from 'react-bootstrap';
import cognitoUtils from '../../lib/cognitoUtils';
import * as Constants from "../../components/Constants";

const mapStateToProps = state => {
  return { session: state.session }
}
class NavBar extends React.Component  {
  state = {
    cognitoUriLogout: '',
};
componentDidMount () {
  let cognitoUriLogout;

  switch (this.props.session.environment) {
    case Constants.localEnvironment :
      cognitoUriLogout = cognitoUtils.getCognitoSignOutUriCog(this.props.session.environment);
      break;
    case Constants.developEnvironment :
      cognitoUriLogout = cognitoUtils.getCognitoSignOutUriCog(this.props.session.environment);
      break;
    case Constants.stagingEnvironment:
      cognitoUriLogout = cognitoUtils.getCognitoSignOutUriFed(this.props.session.environment);
      break;
    case Constants.productionEnvironment:
      cognitoUriLogout = cognitoUtils.getCognitoSignOutUriFed(this.props.session.environment);
      break;
    default:
      cognitoUriLogout = cognitoUtils.getCognitoSignOutUriCog(this.props.session.environment);
  }

  this.setState({
    cognitoUriLogout 
  });
}
  render() {
    const { session } = this.props;
    const { cognitoUriLogout } = this.state;
    return (
      <Container className="NavBar" style={{"maxWidth": "100%", "paddingLeft": "0px", "paddingRight":"0px"}}>
        {(session.user.role === "FULL" && session.user.active) ? 
          <Row>
            <Col className="text-left">
              <img src="/images/logo.png" alt="Accenture" height="28" border="0"/>
            </Col>
            <Col className="text-right">
              <Link to="/Upload" className="link-container-small">Upload</Link>
              <Link to="/Download" className="link-container-small">Download</Link>
              <Link to="/Administration" className="link-container-small">Administration</Link>
              <a href={cognitoUriLogout} className="link-container-small">Logout</a>
            </Col>
          </Row>
      :
        <Row>
          <Col>
            <img src="/images/logo.png" alt="Accenture" height="28" border="0"/>
          </Col>
          <Col  className="text-right">
            <a href={cognitoUriLogout} className="link-container-small">Logout</a>
          </Col>
        </Row>
      }
      </Container>
    );
  }
}

export default connect(mapStateToProps) (NavBar);