import { get_filenames, download_filenames, set_empty_filenames, delete_filenames } from "../../state/actions/Download.actions";

export const getFiles = (url, apK) =>
dispatch => {
    dispatch(get_filenames(url, apK));
}
export const downloadFiles = (url, apK, doZip) =>
dispatch => {
    dispatch(download_filenames(url, apK, doZip));
}
export const deleteFiles = (url, apK, urlRefresh) =>
dispatch => {
    dispatch(delete_filenames(url, apK, urlRefresh));
}
// 25/08/2020 - FIX 4 - juan.d.martin.garcia
// After a search with files in Download window, 
// we change to another one and then we return to Download window again. 
// Result table is filled with the previous search. We must set empty de array.
export const emptyFileNames = () =>
dispatch => {
    dispatch(set_empty_filenames());
}
