import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles, lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import * as Constants from "../Constants";
import "./EnhancedTable.css";
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';


function createData(
  idFile,
  title,
  owner,
  dateUpload,
  type,
  fileSize,
  fileType,
  prev
) {
  return { idFile, title, owner, dateUpload, type, fileSize, fileType, prev };
}

var rows = [];

function descendingComparator(a, b, orderBy) {
  // If by date, to order transform yyyymmdd
  let valor1 = (orderBy==='dateUpload') ? a[orderBy].split("/").reverse().join("") : a[orderBy];
  let valor2 = (orderBy==='dateUpload') ? b[orderBy].split("/").reverse().join("") : b[orderBy];
  
  if (valor2 < valor1) {
    return -1;
  }
  if (valor2 > valor1) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'idFile', numeric: false, disablePadding: true, label: '' },
  { id: 'title', numeric: false, disablePadding: true, label: 'TITLE' },
  { id: 'owner', numeric: false, disablePadding: false, label: 'OWNER' },
  { id: 'dateUpload', numeric: false, disablePadding: false, label: 'DATE' },
  { id: 'type', numeric: false, disablePadding: false, label: 'TYPE' },
  { id: 'fileSize', numeric: false, disablePadding: false, label: 'SIZE' },
  { id: 'fileType', numeric: false, disablePadding: true, label: 'DOC' },
  // { id: "prev", numeric: false, disablePadding: false, label: "PREV" },
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#F7F5F5',
  },
}))(TableCell);

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, numSelected, order, rowCount, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
        <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            className={classes.tableHead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={true}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    'font-family': 'Graphik Web',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          minHeight: 36,
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          minHeight: 36,
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
    'font-family': 'Graphik Web',
    'font-size': '0.85em',
    'text-align': 'left',
  },
  iconButton: {
    'border-radius': '0%',
  },
  customizeToolbar: {
    minHeight: 36,
    height: 44,
    color: "#444",
    backgroundColor: "#FFFFFF",
    "padding-left": "15px",
    "padding-right": "0px",
  },
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  const role = props.role;

  const handleDownloads = () => {
    props.selectedDownload();
  };

  const handleUpdate = () => {
    props.selectedUpdate();
  };

  const handleDeletes = () => {
    props.selectedDelete();
  };

  const handlePreview = () => {
      props.selectedPreview();
  };

  return (
    <Toolbar
      className={clsx(classes.customizeToolbar, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} variant="h6" id="tableTitleSelected" component="div">
          <b>{numSelected}</b> item selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {Constants.resultsFound_ini}
            <b>{rows.length}</b>
          {Constants.resultsFound_fin}
        </Typography>
      )}

      {numSelected === 1 ? (
        <Tooltip title="Preview">
          <IconButton className={classes.iconButton} aria-label="preview" onClick={(event) => handlePreview(event)}>
            <div className="DownloadIcon">PREVIEW</div>
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <div>
          <IconButton className={classes.iconButton} aria-label="preview" disabled>
            <div className="DownloadIcon">PREVIEW</div>
            <VisibilityIcon />
          </IconButton>
        </div>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Download">
          <IconButton className={classes.iconButton} aria-label="download" onClick={(event) => handleDownloads(event)}>
            <div className="DownloadIcon">DOWNLOAD</div>
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <div>
          <IconButton className={classes.iconButton} aria-label="download" disabled>
            <div className="DownloadIcon">DOWNLOAD</div>
            <GetAppIcon />
          </IconButton>
        </div>
      )}

      {numSelected === 1 && role === 'FULL' ? (
        <Tooltip title="Update">
          <IconButton className={classes.iconButton} aria-label="update" role={role} onClick={(event) => handleUpdate()}>
            <div className="UpdateIconActive">UPDATE</div>
            <EditIcon style={{ color: 'red' }}/>
          </IconButton>
        </Tooltip>
      ) : (
        <div>
          <IconButton className={classes.iconButton} aria-label="update" disabled>
            <div className="UpdateIconDeactive">UPDATE</div>
            <EditIcon />
          </IconButton>
        </div>
      )}

      {numSelected > 0 && role === 'FULL' ? (
        <Tooltip title="Delete">
          <IconButton className={classes.iconButton} aria-label="delete" role={role} onClick={(event) => handleDeletes()}>
            <div className="DeleteIconActive">DELETE</div>
            <DeleteForeverIcon style={{ color: 'red' }}/>
          </IconButton>
        </Tooltip>
      ) : (
        <div>
          <IconButton className={classes.iconButton} aria-label="delete" disabled>
            <div className="DeleteIconDeactive">DELETE</div>
            <DeleteForeverIcon />
          </IconButton>
        </div>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    'font-family': 'Graphik Web',
    width: '100%',
  },
  paper: {
    'font-family': 'Graphik Web',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    'font-family': 'Graphik Web',
    minWidth: 750,
  },
  tableHead: {
    'font-family': 'Graphik Web'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  colHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 0,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 0,
  },
  colTitle: {
    'font-family': 'Graphik Web',
    color: '#A100FF',
  },
  colFont: {
    "font-family": "Graphik Web",
  },
  colFontView: {
    "font-family": "Graphik Web",
    paddingLeft: 5,
  },
  selected: {
    backgroundColor: '#F7F5F5',
  },
  tablePagination: {
    "& .MuiTablePagination-caption": {
      'font-family': 'Graphik Web',
    },
    "& .MuiTablePagination-input": {
      'font-family': 'Graphik Web',
    },
    "& .MuiTablePagination-select": {
      'font-family': 'Graphik Web',
    },
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: 24,
    padding: "16px 32px 24px",
  },
  textfield: {
    with: "100%",
  },
}));

export default function EnhancedTable(data) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('dateUpload');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  rows = [];

  if (data.filenames.length>0) {
    data.filenames.forEach(element => {
      rows.push(createData(element.idFile, element.title, element.owner, 
        element.dateUpload, element.type, element.fileSize, element.fileType),);      
    });
  }

  const selectedDownload = () => {
    data.handleDownloads(selected);
  };

  const selectedUpdate = () => {
    data.handleUpdate(selected);
  };

  const selectedDelete = () => {
    data.handleDeletes(selected);
  };

  const selectedPreview = () => {
    data.handlePreview(selected);
  };

  const role = data.role;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.idFile);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, idFile) => {
    const selectedIndex = selected.indexOf(idFile);
    let newSelected = [];
    let iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, idFile);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    
    if ((iOS) && (selected.length>0) && (newSelected.length>0)) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
    
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handlePreview = (event, idFile, fileType, isLoading) => {
  //   selectedPreview(idFile, fileType, isLoading);
  // };

  const truncate = (str, length) => {
    return str.length > length ? str.substring(0, (length - 3)) + "..." : str;
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

   return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} selectedDownload={selectedDownload} selectedUpdate={selectedUpdate} selectedDelete={selectedDelete} selectedPreview={selectedPreview} role={role}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              displaySelectAll="false"
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.idFile);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      className={classes.tableRow}
                      onClick={(event) => handleClick(event, row.idFile)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.idFile}
                      selected={isItemSelected}
                    >       
                      <TableCell padding="checkbox">
                        <Checkbox
                          checkedIcon={
                            <CheckBoxOutlinedIcon style={{ fill: "#000000" }} />
                          }
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell className={classes.colHidden} component="th" id={labelId} scope="row" padding="none">
                        {row.idFile}
                      </TableCell>
                      {row.title.length > 37 ? (
                        <TableCell className={classes.colTitle} align="left">
                          <Tooltip
                            title={row.title}
                            placement="right-start"
                            arrow
                          >
                            <div>{truncate(row.title, 37)}</div>
                          </Tooltip>
                        </TableCell>
                      ) : (
                        <TableCell className={classes.colTitle} align="left">
                          {row.title}
                        </TableCell>
                      )}
                      <TableCell className={classes.colFont} align="left">
                        {row.owner}
                      </TableCell>
                      <TableCell className={classes.colFont} align="left">
                        {row.dateUpload}
                      </TableCell>
                      <TableCell className={classes.colFont} align="left">
                        {row.type}
                      </TableCell>
                      <TableCell className={classes.colFont} align="left">
                        {row.fileSize}
                      </TableCell>
                      <TableCell className={classes.colFont} align="left">
                        {typeof row.fileType === "undefined" ? (
                          <p>&nbsp;</p>
                        ) : row.fileType.toLowerCase() === "avi" ||
                          row.fileType.toLowerCase() === "mp4" ? (
                          <img
                            src="/images/icons/avi.svg"
                            alt={row.fileType}
                            height="32"
                            border="0"
                          />
                        ) : row.fileType.toLowerCase() === "pdf" ? (
                          <img
                            src="/images/icons/pdf.svg"
                            alt={row.fileType}
                            height="32"
                            border="0"
                          />
                        ) : row.fileType.toLowerCase() === "zip" ? (
                          <img
                            src="/images/icons/zip.svg"
                            alt={row.fileType}
                            height="32"
                            border="0"
                          />
                        ) : row.fileType.toLowerCase() === "jpg" ||
                          row.fileType.toLowerCase() === "jpeg" ? (
                          <img
                            src="/images/icons/jpg.svg"
                            alt={row.fileType}
                            height="32"
                            border="0"
                          />
                        ) : row.fileType.toLowerCase() === "doc" ||
                          row.fileType.toLowerCase() === "docx" ||
                          row.fileType.toLowerCase() === "dotx" ? (
                          <img
                            src="/images/icons/word.svg"
                            alt={row.fileType}
                            height="32"
                            border="0"
                          />
                        ) : row.fileType.toLowerCase() === "ppt" ||
                          row.fileType.toLowerCase() === "pptx" ? (
                          <img
                            src="/images/icons/ppt.svg"
                            alt={row.fileType}
                            height="32"
                            border="0"
                          />
                        ) : (
                          <img
                            src="/images/icons/more.svg"
                            alt={row.fileType}
                            height="32"
                            border="0"
                          />
                        )}
                      </TableCell>
                      {/* <TableCell className={classes.colFontView} align="left">
                        {row.prev}
                        <IconButton
                          className={classes.iconButton}
                          aria-label="viewDocument"
                        >
                          <VisibilityIcon
                            fontSize="large"
                            style={{ color: "black" }}
                            onClick={(event) => handlePreview(event, row.idFile, row.fileType)}
                          >
                          </VisibilityIcon>
                        </IconButton>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.tablePagination}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
     </div>
  );
}