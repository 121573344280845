import { api_call } from '../../core/api/api.actions';
import * as types from '../types/Upload.types';
import appConfig from '../../config/app-config.json';
import Axios from "axios";

export const set_filename = (fileName) => ({
  type: types.SET_FILE_NAME,
  payload: { fileName },
});

export const set_json = (json) => ({
  type: types.SET_JSON,
  payload: { json },
});

export const set_file = (file) => ({
  type: types.SET_FILE,
  payload: { file },
});
     
export const set_message = (message) => ({
  type: types.SET_MESSAGE,
  payload: { message },
});

export const set_uploading_files = (uploadingFile) => ({
  type: types.SET_UPLOADING_FILES_VALUE,
  payload: { uploadingFile },
});

  export const upload_filename = (url, environment, apK, json, file) => dispatch => {
    //const appConfigEnt = appConfig[environment];
    const idFile_aux = json.idFile;
    dispatch(set_uploading_files(true));
    // If we are Modifying and we have a file
    if ((json.idFile !== "") && (!!file)) json.idFile="";
    return dispatch( 
      api_call(url, {
        method: 'PUT',
        responseType: 'json',
        headers: {
          Authorization: 'validToken',
          'Content-Type': 'application/json',
          "x-api-key": apK,
        },
        data:json,
      }),
    ).then(fileToUpload => {
        let uploadUrl = fileToUpload.uploadUrl;
        Axios.put(uploadUrl, file).then(res => {
          // If we are Modifying and we have a file, delete idFile Register
          if ((idFile_aux !== "") && (!!file)) {
            json.idFile=idFile_aux;
            dispatch(delete_upload(environment, apK, json.idFile, json.loggedEid ));
          } else {
            if (idFile_aux !== "") {
              dispatch(set_message({ type: "success", msg: "The Data has been updated successfully!"}));
              dispatch(set_message({}));
              dispatch(set_uploading_files(false));
            } else {
              dispatch(set_message({ type: "success", msg: "The document has been uploaded successfully!"}));
              dispatch(set_message({}));
              dispatch(set_uploading_files(false));
            }
          }
        }).catch(err => 
          {
            console.log(err);
            dispatch(set_message({ type: "error", msg: "Something was wrong. Please, try again!"}));
            dispatch(set_message({}));
            dispatch(set_uploading_files(false));
          });
      }
    ).catch(err => {
      console.log(err);
      dispatch(set_message({ type: "error", msg: "Something was wrong. Please, try again!"}));
      dispatch(set_message({}));
      dispatch(set_uploading_files(false));
    });
}

export const delete_upload = (environment, apK, idFile, loggedEid) => dispatch => {
  const appConfigEnt = appConfig[environment];
  const stringParams = "?loggedEid=" + loggedEid + "&idFiles="+idFile;
  const url = `${appConfigEnt.apiUri}/inventory` + stringParams;

  return dispatch(
    api_call(url, {
      method: 'DELETE',
      responseType: 'json',
      headers: {
        Authorization: 'validToken',
        'Content-Type': 'application/json',
        "x-api-key": apK,
      },
    }),
  ).then(filesDeleted => {
      // Success
      dispatch(set_message({ type: "success", msg: "File(s) and data updated(s)…"}));
      dispatch(set_message({})); 
      dispatch(set_uploading_files(false));
    }
  ).catch(err => {
    console.log(err);
    dispatch(set_message({ type: "error", msg: "Something was wrong. Please, try again!"}));
    dispatch(set_message({}));
    dispatch(set_uploading_files(false));
  });
}