import React from "react";
import "./DragAndDrop.css";
import Dropzone from "react-dropzone";
import * as Constants from "../Constants";

class DragAndDrop extends React.Component {
  state = {
    file: ""
  };

  handleFile(file) {
    this.setState({ file: file });
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
      this.props.handleFile(file, event.target.result);
    };
}

  render() {
    const { dropzoneStyleError, dropzoneStyleActive, dropzoneStyle } = Constants.styles;

    return (
      <Dropzone
        onDrop={(acceptedFile) => {
          if (!!acceptedFile[0] && acceptedFile[0] !== undefined) {
            this.handleFile(acceptedFile[0]);
          } else {
            this.setState({ file: "" });
            this.props.handleFile("", "");
          }
        }}
        multiple={false}
        accept={
          "application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.wordprocessingml.template, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, image/jpeg, image/jpg, application/x-zip-compressed, image/png, video/x-msvideo, video/mp4"
        }
        maxSize={52428800}
        activeStyle={dropzoneStyleActive}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div id="drag" {...getRootProps()}>
              <input name="fileToUpload" {...getInputProps()} />
              {!!this.state.file ? (
                <p style={dropzoneStyle}>{this.state.file.name}</p>
              ) : this.props.className !== "" ? (
                <p style={dropzoneStyleError}>
                  Drag the file here, or click to select file. Max file size 50 MB
                </p>
              ) : (
                <p>Drag the file here, or click to select file. Max file size 50 MB</p>
              )}
            </div>
          </section>
        )}
      </Dropzone>
    );
  }
}

export default DragAndDrop;
