import * as types from '../types/Admin.types';

const initialState = {
  message: {},
  downloadingFile: false,
  updatingTag: false
};

const admin = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
        case types.SET_LOADING_VALUE:
        return {...state, ...payload};
        case types.SET_MESSAGE:
        return {...state, ...payload};
        case types.SET_DOWNLOADING_FILES_VALUE:
          return {...state, ...payload};
        case types.SET_UPDATING_TAG_VALUE:
          return {...state, ...payload};
        case types.SET_INSERTING_NEW_TAG_VALUE:
          return {...state, ...payload};  
    default:
      return state;
  }
};

export default admin;
