import React from "react";
import "./Form.css";
import DragAndDrop from "../DragAndDrop/DragAndDrop";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Constants from "../Constants";
import Tags from "../Tags/Tags";
import { Fetch } from '../../services/api';
import Spinner from "react-bootstrap/Spinner";
import { Row, Col } from 'react-bootstrap';
import appConfig from '../../config/app-config.json';
import { connect } from 'react-redux';
import { uploadFilename, setMessage } from './Form.actions';

const initialState = {
  showUpload: false,
  showDetails: false,
  type: "BRANDING",
  dm: "NA",
  group: "IPS",
  subgroup: "MICROSOFT",
  lang: "SPANISH",
  dataIncluded: false,
  photoVideo: false,
  desc: "",
  tech: [],
  people: [],
  title: "",
  og: "CMT",
  owner: "",
  date: "",
  titleClass: "tittle",
  ownerClass:"owner",
  peopleClass:"people",
  dmClass: "dm",
  descClass: "description",
  techClass: "root",
  fileClass: "",
  clearForm: true,
  tagsLoaded: false,
  idFile: "",
  fileType: "",
  fileName: "",
  fileSize: "",
  initialKeywords: [],
};

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.state.owner = this.props.session.user.eid;
    this.dragDrop = React.createRef();
    this.PVIyes = React.createRef();
    this.PVIno = React.createRef();
    this.PDIyes = React.createRef();
    this.PDIno = React.createRef();    
  }

  handleTT = (param) => {
    this.setState({
      tech: param
    });
  };

  handleLoad = () => {
    this.setState({
      tagsLoaded: true
    });
  };
  
  // Get DragAndDrop's state from props
  handleFile = (file, base64) => {
    this.setState({
      file: file,
      base64: base64,
    });
  };

  setOptions = (options) => {
    let list = [];
    options.forEach((o) => {
      list.push(<option key={o}>{o}</option>);
    });
    return list;
  };

  //If has a param, show the upload input, else hide it
  toggleUpload = (toggle) => {
    !!toggle
      ? this.setState({ showUpload: true })
      : this.setState({ showUpload: false, file: "", base64: "" });
  };

  //If has a param, show the info span, else hide it
  toggleInfo = (toggle) => {
    !!toggle
      ? this.setState({ showDetails: true })
      : this.setState({ showDetails: false });
  };

  adaptState = (state) => {
    return state.replace(/ /g, "-").toLowerCase();
  };

  adaptFileSize = (state) => {
    var i = -1;
    var byteUnits = [' KB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
      state = state / 1024;
        i++;
    } while (state > 1024); 
    return Math.max(state, 0.1).toFixed(1) + byteUnits[i];
  };

  adaptFileExtension = (state) => {
    return state.slice((state.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase()
  };

  adaptPeopleList = (state) => {
    if (state.length === 0) {
      return [""];
    } else {
      return state.split(";");
    }
  };

  // Create Upload JSON, doing the Property Mapping to send to the REST API
  informJSON = (uploadFileJSON) => {
    const auxPVI = (this.PVIyes.current.checked) ? true : false;
    const auxDI = (this.PDIyes.current.checked) ? true : false;
    let auxUploadFileJSON = {
      idFile: this.state.idFile,
      type: this.state.type,
      dm: this.state.dm,
      title: this.state.title,
      description: this.state.desc,
      og: this.state.og,
      bu: this.state.group,
      subBu: this.state.subgroup,
      language: this.state.lang,
      keywords: this.state.tech,
      dateUpload: '',
      owner: this.state.owner,
      loggedEid: this.props.session.user.eid,
      photoVideoIncluded: auxPVI,
      personalDataIncluded: auxDI,
      peopleIncluded: this.adaptPeopleList(this.state.people),
      status: 'APPROVED'
    };

    if (!!!this.state.file) {
      auxUploadFileJSON.fileType=this.state.fileType;
      auxUploadFileJSON.fileName=this.state.fileName;
      auxUploadFileJSON.fileSize=this.state.fileSize;
    } else {
      auxUploadFileJSON.fileType=this.adaptFileExtension(this.state.file.name);
      auxUploadFileJSON.fileName=this.state.file.name;
      auxUploadFileJSON.fileSize=this.adaptFileSize(this.state.file.size);
    }
    return auxUploadFileJSON;
  };

  //Validate form
  validate = () => {
    const { title, desc, tech, file, owner, idFile} = this.state;
    const format = !!file ? (this.adaptFileExtension(file.name)).toUpperCase() : "";
    const flagValidFormat = format.match(/^(PDF|AVI|MP4|DOTX|DOCX|DOC|PPT|PPTX|JPEG|JPG|ZIP|PNG)$/);

    // eslint-disable-next-line no-unused-expressions
    !!!title
      ? (this.notify("error", "Title is mandatory!"),
        this.setState({ dmClass: "dm", peopleClass: "people", ownerClass: "owner", titleClass: "error", descClass: "description", techClass: "root", fileClass: "" }))
      : !!!desc
      ? (this.notify("error", "Description is mandatory!"),
        this.setState({ dmClass: "dm", peopleClass: "people", ownerClass: "owner", titleClass: "tittle", descClass: "error", techClass: "root", fileClass: "" }))
      : !!!owner
      ? (this.notify("error", "Owner is mandatory!"),
        this.setState({ dmClass: "dm", peopleClass: "people", ownerClass: "error", titleClass: "tittle", descClass: "description", techClass: "root", fileClass: "" }))
      : tech.length === 0
      ? (this.notify("error", "Technologies & Tools is mandatory!"),
        this.setState({ dmClass: "dm", peopleClass: "people", ownerClass: "owner", titleClass: "tittle", descClass: "description", techClass: "error", fileClass: "" }))
      : (!!!file && idFile==="")
      ? (this.notify("error", "File is mandatory!"),
        this.setState({ dmClass: "dm", peopleClass: "people", ownerClass: "owner", titleClass: "tittle", descClass: "description", techClass: "root", fileClass: "dropzoneStyleError" }))
      : (!!!flagValidFormat && idFile==="")
      ? (this.notify("error", "Invalid file format! Allowed files: PDF|AVI|MP4|DOTX|DOCX|DOC|PPT|PPTX|JPEG|JPG|ZIP|PNG"),
        this.setState({ dmClass: "dm", peopleClass: "people", ownerClass: "owner", titleClass: "tittle", descClass: "description", techClass: "root", fileClass: "dropzoneStyleError" }))
      : this.uploadFile();
  };

  uploadFile = () => {
    this.setState({ peopleClass: "people", dmClass: "dm", titleClass: "tittle", ownerClass: "owner", descClass: "description", techClass: "root", fileClass: "" });
    const appConfigEnt = appConfig[this.props.session.environment];
    const url = `${appConfigEnt.apiUri}/inventory`;

    let uploadFileJSON;
    uploadFileJSON = this.informJSON(uploadFileJSON);
    this.props.uploadFilename(url, this.props.session.environment, this.props.session.user.apK, uploadFileJSON, this.state.file);

  };

  clearUpload = () => {
    // Type
    document.getElementById("type").value = initialState.type;
    // dm
    document.getElementById("dm").value = initialState.dm;
    document.getElementById("dm").disabled=true;
    document.getElementById("dm").style.backgroundColor="#d3c7c798";
    // title
    document.getElementById("title").value = initialState.title;
    // desc
    document.getElementById("desc").value = initialState.desc;
    // og
    document.getElementById("og").value = initialState.og;
    // bg
    document.getElementById("bg").value = initialState.subgroup;
    // language
    document.getElementById("language").value = initialState.lang;
    // tech
    document.getElementById("tech").parentNode.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();
    // owner
    document.getElementById("owner").value = this.props.session.user.eid;
    // file
    this.handleFile("", "");
    this.dragDrop.current.setState({file: "", idFile: ""});
    // Photo/Video Included
    this.PVIno.current.checked=true;
    // People
    document.getElementById("people").value = initialState.people;
    document.getElementById("people").disabled=true;
    document.getElementById("people").style.backgroundColor="#d3c7c798";
    // Personal Data Included
    this.PDIno.current.checked=true;
  }

  setModifyElement = () => {
    const { tech } = this.state;
    let  auxPeople = "";
    //console.log(this.props.session.modifyElement);
    // Type
    document.getElementById("type").value = this.props.session.modifyElement.type;
    // dm
    document.getElementById("dm").value = this.props.session.modifyElement.dm;
    if (document.getElementById("type").value==="CLIENT CASE STORY") {
      document.getElementById("dm").disabled=false;
      document.getElementById("dm").style.backgroundColor="white";
    }
    // title
    document.getElementById("title").value = this.props.session.modifyElement.title;
    // desc
    document.getElementById("desc").value = this.props.session.modifyElement.description;
    // og
    document.getElementById("og").value = this.props.session.modifyElement.og;
    // bg
    document.getElementById("bg").value = this.props.session.modifyElement.subBu;
    // language
    document.getElementById("language").value = this.props.session.modifyElement.language;
    // tech
    //document.getElementById("tech").changeHandler();
    // owner
    document.getElementById("owner").value = this.props.session.modifyElement.owner;
    // Photo/Video Included
    if (this.props.session.modifyElement.photoVideoIncluded) {
      this.PVIyes.current.checked=true;
    } else {
      this.PVIno.current.checked=true;
    }
    // Personal Data Included
    if (this.props.session.modifyElement.personalDataIncluded) {
      this.PDIyes.current.checked=true;
      // People
      document.getElementById("people").disabled=false;
      document.getElementById("people").style.backgroundColor="white";
      if (this.props.session.modifyElement.peopleIncluded.length>0) {
        this.props.session.modifyElement.peopleIncluded.forEach(
          actualElement => {
            auxPeople+=actualElement+";";
          }
        );
        auxPeople = auxPeople.substring(0,auxPeople.length-1);
      }
      document.getElementById("people").value=auxPeople;
    } else {
      this.PDIno.current.checked=true;
    }
    this.props.session.modifyElement.keywords.forEach(
      actualElement => {
        tech.push(actualElement);
      }
    );
    this.setState({
      type: this.props.session.modifyElement.type,
      dm: this.props.session.modifyElement.dm,
      title: this.props.session.modifyElement.title,
      desc: this.props.session.modifyElement.description,
      og: this.props.session.modifyElement.og,
      bg: this.props.session.modifyElement.subBu,
      lang: this.props.session.modifyElement.language,
      owner: this.props.session.modifyElement.owner,
      photoVideo: this.props.session.modifyElement.photoVideoIncluded,
      dataIncluded: this.props.session.modifyElement.personalDataIncluded,
      people: auxPeople,
      tagsLoaded: false,
      idFile: this.props.session.modifyElement.idFile,
      fileType: this.props.session.modifyElement.fileType,
      fileName: this.props.session.modifyElement.fileName,
      fileSize: this.props.session.modifyElement.fileSize,
    });
    // End of setModifyElement
    this.props.session.modifyMode=null;
    this.props.session.modifyElement=null;
  }

  updateAsyc = () => {
    return new Promise((resolve, reject) => {
      let exito = false;             
      if (        
        null != document.getElementById("type") &&
        null != document.getElementById("dm") &&
        null != document.getElementById("og") &&
        null != document.getElementById("bg") &&
        null != document.getElementById("language")        
        ) 
        exito = true;
        //console.log(exito);         
        if (exito) {
          // Si la operaci�n fue exitosa, resuelve la promesa con un valor
          this.setModifyElement();
          resolve("�Operaci�n exitosa!");  
        } else {
          // Si la operaci�n fall�, rechaza la promesa con un error
          setTimeout(() => { this.updateAsyc() }, 1000);
          reject(new Error("�La operaci�n fall�!"));
        }        
      });
    }

  //Toasts
  notify = (type, msg) => toast[type]((type === "error" ? "" : " ") + msg);

  info;

  UNSAFE_componentWillMount = () => {
    // Validate if we are in modifyMode
    if (null != this.props.session.modifyMode && 
      typeof this.props.session.modifyMode != 'undefined' && 
      this.props.session.modifyMode) 
        this.setState({
          initialKeywords: this.props.session.modifyElement.keywords,
        });
  }

  componentDidUpdate = () => {
    const { clearForm, tagsLoaded, keywords } = this.state;
    const tech_aux = this.state.tech;
    let estado = initialState;
    estado.keywords = keywords;

    if (clearForm) {
      if (this.props.message.type === 'success') {
        estado.clearForm = false;
        estado.owner = this.props.session.user.eid;
        estado.tech = tech_aux;
        this.setState(estado);
        //this.setState({clearForm: false});
        this.clearUpload();
      } else {
        estado.tech=[];
      }
    } else {
      this.setState({clearForm: true});
      this.props.setMessage({});
    }
    // Validate if we are in modifyMode & every element is loaded
    /*if (null != this.props.session.modifyMode && 
        typeof this.props.session.modifyMode != 'undefined' && 
        this.props.session.modifyMode && 
//        null != document.getElementById("type") &&
//        null != document.getElementById("dm") &&
//        null != document.getElementById("og") &&
//        null != document.getElementById("bg") &&
//        null != document.getElementById("language") &&
        tagsLoaded) 
        modeUpdate();*/
    this.updateAsyc()
      .then(resultado => {
        // Manejar el resultado si la promesa se resuelve correctamente
        if (null != this.props.session.modifyMode && 
          typeof this.props.session.modifyMode != 'undefined' && 
          this.props.session.modifyMode && 
          tagsLoaded)
          console.log(resultado);
      })
      .catch(error => {
        // Manejar el error si la promesa es rechazada
        console.error(error);
      });   




  }











  render() {
    const { titleClass, descClass, techClass, ownerClass, peopleClass, dmClass, fileClass, clearForm, initialKeywords } = this.state;
    const { message } = this.props;

    // Message
    if (typeof message.type !== 'undefined') {
      if (clearForm) this.notify(message.type, message.msg);
    }

    return (
      <div className="Form">
      <div>
        <img src="/images/pixelt.png" alt="" width="1" height="25" border="0"/>
      </div>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/*Type (Select)*/}
          <h2>Type</h2>
          <Fetch path={'/'} options="type">
                  {({ data, loading, error }) => {
                      if (error) {
                          return <p>{error.message}</p>;
                      }
                      if (loading) {
                          return <Spinner animation="border" style={{color: "white"}}></Spinner>;
                      }
                      if (data) {
                          return <select
                            id="type"
                            onChange={(event) => {
                              if (event.target.value==="CLIENT CASE STORY") {
                                document.getElementById("dm").disabled=false;
                                document.getElementById("dm").style.backgroundColor="white";
                              } else {
                                document.getElementById("dm").value="NA";
                                this.setState({ dm: "NA" })
                                document.getElementById("dm").disabled=true;
                                document.getElementById("dm").style.backgroundColor="#d3c7c798";
                              }
                              this.setState({ type: event.target.value })
                            }}
                          >
                            {this.setOptions(data)}
                          </select>;
                      }
                      return <Spinner animation="border" style={{color: "white"}}></Spinner>;
                  }}
          </Fetch>
        </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/*Development/Maintenance (Select)*/}
          <h2>Development/Maintenance</h2>
          <Fetch path={'/'} options="dm">
                  {({ data, loading, error }) => {
                      if (error) {
                          return <p>{error.message}</p>;
                      }
                      if (loading) {
                          return <Spinner animation="border" style={{color: "white"}}></Spinner>;
                      }
                      if (data) {
                        return <select
                            disabled={true}
                            id="dm"
                            className={dmClass}
                            onChange={(event) =>
                              this.setState({ dm: event.target.value })
                            }
                          >
                            {this.setOptions(data)}
                          </select>;
                
                      }
                      return <Spinner animation="border" style={{color: "white"}}></Spinner>;
                  }}
          </Fetch>
        </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/*Title (Text)*/}
          <h2>Title</h2>
          <input
            type="text"
            id="title"
            className={titleClass}
            onChange={(event) =>
              this.setState({ title: event.target.value })
            }
          />
        </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/*Description (Text)*/}
          <h2>Description</h2>
          <h3>Briefly explain the content and utility of the document to upload. Recommended maximum length 100 characters in Spanish.</h3>
          <textarea
            id="desc"
            className={descClass}
            onChange={(event) =>
              this.setState({ desc: event.target.value })
            }
          ></textarea>
       </Col>
       <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/*OG (Select)*/}
          <h2>OG</h2>
          <Fetch path={'/'} options="og">
                  {({ data, loading, error }) => {
                      if (error) {
                          return <p>{error.message}</p>;
                      }
                      if (loading) {
                          return <Spinner animation="border" style={{color: "white"}}></Spinner>;
                      }
                      if (data) {
                        return <select
                          id="og"
                          onChange={(event) =>
                            this.setState({ og: event.target.value })
                          }
                        >
                          {this.setOptions(data)}
                        </select>;
                      }
                      return <Spinner animation="border" style={{color: "white"}}></Spinner>;
                  }}
          </Fetch>
        </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/*BG (Select)*/}
          <h2>Business Group</h2>
          <Fetch path={'/'} options="bg">
                  {({ data, loading, error }) => {
                      if (error) {
                          return <p>{error.message}</p>;
                      }
                      if (loading) {
                          return <Spinner animation="border" style={{color: "white"}}></Spinner>;
                      }
                      if (data) {
                        let IPS=data.slice(1, data.indexOf('CLOUD FIRST'));
                        let CLOUD_FIRST=data.slice(data.indexOf('CLOUD FIRST')+1, data.indexOf('CROSS'));
                        let CROSS=data.slice(data.indexOf('CROSS')+1, data.length);
                        return <select
                          id="bg"
                          onChange={(event) =>
                            this.setState({ group: event.target.options[event.target.selectedIndex].parentNode.label, subgroup: event.target.value })
                          }
                        >
                        <optgroup label="IPS">
                          {this.setOptions(IPS)}
                        </optgroup>
                        <optgroup label="CLOUD FIRST">
                          {this.setOptions(CLOUD_FIRST)}
                        </optgroup>
                        <optgroup label="CROSS">
                          {this.setOptions(CROSS)}
                        </optgroup>
                        </select>;
                      }
                      return <Spinner animation="border" style={{color: "white"}}></Spinner>;
                  }}
          </Fetch>
        </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/*Language (Select)*/}
          <h2>Language</h2>
          <Fetch path={'/'} options="language">
                  {({ data, loading, error }) => {
                      if (error) {
                          return <p>{error.message}</p>;
                      }
                      if (loading) {
                          return <Spinner animation="border" style={{color: "white"}}></Spinner>;
                      }
                      if (data) {
                        return <select
                          id="language"
                          onChange={(event) =>
                            this.setState({ lang: event.target.value })
                          }
                        >
                          {this.setOptions(data)}
                        </select>;
                      }
                      return <Spinner animation="border" style={{color: "white"}}></Spinner>;
                  }}
          </Fetch>
        </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/*TECHNOLOGIES & TOOLS (Predictive Text)*/}
          <h2>Search Tags</h2>
          <h3>Assign to the document the tags that you consider most opportune. Keep in mind that a correct categorization will facilitate its positioning and search, so we recommend assigning a minimum of 3 tags. You can select them from the list or write them and the application will suggest the appropriate tags. If you can't find the tags you need, suggest it <a href="mailto:spainatc.comms@accenture.com?subject=Inventory%20-%20Tags%20suggestion">here</a>.</h3>
          <Fetch path={'/'} options="tags">
            {({ data, loading, error }) => {
                if (error) {
                    return <p>{error.message}</p>;
                }
                if (loading) {
                    return <Spinner animation="border" style={{color: "white"}}></Spinner>;
                }
                if (data) {
                  return  <Tags 
                      id="tech"
                      className={techClass}
                      width="100%"
                      freeSolo={false}
                      changeHandler={this.handleTT}
                      tags={data}
                      values={initialKeywords}
                      loadHandler={this.handleLoad}
                    />
                }
                return <Spinner animation="border" style={{color: "white"}}></Spinner>;
            }}
          </Fetch>
          </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/*Owner (Text)*/}
          <h2>Owner</h2>
          <h3>Use the Enterprise ID of the person responsible for the file (without @accenture).</h3>
          <input
            type="text"
            id="owner"
            className={ownerClass}
            value={this.state.owner}
            onChange={(event) =>
              this.setState({ owner: event.target.value })
            }
          />
        </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 justify-content-center text-center">
          {/*PHOTO/VIDEO INCLUDED (Radio)*/}
          <h2>Photo/Video Included</h2>
          <input
            type="radio"
            id="yes"
            ref={this.PVIyes}
            name="fileRadio"
            value="true"
            onChange={(event) => this.setState({ photoVideo: true })}
          ></input>
          <label id="yes">Yes</label>
          <span> </span>
          <input
            type="radio"
            id="no"
            ref={this.PVIno}
            name="fileRadio"
            value="false"
            defaultChecked
            onChange={(event) => this.setState({ photoVideo: false })}
          ></input>
          <label id="no">No</label>
          </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 justify-content-center text-center">
          {/*PERSONAL DATA INCLUDED (Select)*/}
          <h2>Personal Data Included</h2>
          <input
            type="radio"
            id="yes"
            ref={this.PDIyes}
            name="dataRadio"
            value="true"
            onChange={(event) => this.setState({ dataIncluded: true })}
            onClick={(event) => {
              document.getElementById("people").disabled=false;
              document.getElementById("people").style.backgroundColor="white";
              this.toggleInfo("yes")
            }}
          ></input>
          <label id="yes">Yes</label>
          <span> </span>
          <input
            type="radio"
            id="no"
            ref={this.PDIno}
            name="dataRadio"
            value="false"
            defaultChecked
            onChange={(event) => this.setState({ dataIncluded: false })}
            onClick={(event) => {
              this.setState({ people: this.adaptState("") });
              document.getElementById("people").disabled=true;
              document.getElementById("people").style.backgroundColor="#d3c7c798";
              this.toggleInfo()
            }}
          ></input>
          <label id="no">No</label>
        </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/*People included (Text)*/}
          <h2>People included</h2>
          <h3>Use the Enterprise ID of the people (without @accenture.com) whose personal data appears in the file. Separation between elements with character ;</h3>
          <input
            type="text"
            id="people"
            disabled={true}
            className={peopleClass}
            value={this.state.people}
            onChange={(event) =>
              this.setState({ people: event.target.value })
            }
          />
        </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {(this.info = Constants.disclaimer)}
         {/*this.state.showDetails ? (this.info = Constants.disclaimer) : ""*/}
        </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 mx-auto">
          {/* {this.state.showUpload ? ( */}
          <DragAndDrop ref={this.dragDrop} handleFile={this.handleFile} className={fileClass} />
          {/* ) : ("")} */}
        </Col>
        <Col xs={1}>&nbsp;</Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col xs={1}>&nbsp;</Col>
        <Col xs={10} className="w-100 px-0 d-flex justify-content-center text-center">
          {/*Submit*/}
          <button id="boton" onClick={() => this.validate()}>Submit</button>
        </Col>
        <Col xs={1}>&nbsp;</Col>
        </Row>
      </div>
    );
  }
}
const mapDispatchToProps = {
  uploadFilename, setMessage 
}

const mapStateToProps = state => {
  return { session: state.session,
          filename: state.download.fileName, 
          message: state.download.message,
        }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form);