import React from "react";
import "./Download.css";
import { connect } from 'react-redux'
import { Filter } from "../../components";
import { Container } from 'react-bootstrap';
import Spinner from "react-bootstrap/Spinner";
import Resize from "../../components/Resize/Resize";
import { toast } from "react-toastify";
import * as Constants from "../../components/Constants";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import LoadingOverlay from 'react-loading-overlay';

const mapStateToProps = state => {
  return { session: state.session, downloadingFile: state.download.downloadingFile, deletingFile: state.download.deletingFile,}
}
class Download extends React.Component {
  state = {
    heightAval: window.innerHeight-488.23,
    downloadingFile: false,
    deletingFile: false,
  };

  // Handler Window's Resize
  handleResize = (width, height) => {
    const heightAval=height-488.23;
    this.setState({
      heightAval 
    });
  };
  //Toasts
  notify = (type, msg) => toast[type]((type === "error" ? "" : " ") + msg);
  
  componentDidMount () {
    // If it's not logged in, redirect to Home
    if (!this.props.session.isLoggedIn) window.location.replace("/");
    var iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      this.notify("warn", Constants.noticeIos);
    }
  }
  
  render() {
    const { session } = this.props;
    const { heightAval } = this.state;
    const { downloadingFile } = this.props;
    const { deletingFile } = this.props;
    LoadingOverlay.propTypes = undefined;
    
    return (
      <LoadingOverlay active={deletingFile} text={"Deleting file(s) and data..."} spinner>
      <LoadingOverlay active={downloadingFile} text={"Preparing file to download..."} spinner>
      <div className="Download">
        <Resize changeResize={this.handleResize}/>
        {session.isLoggedIn && (
          <Container className="container-lg" style={{"maxWidth": "85%"}}>
            <Header />
            <Filter/>
            <div>
              <img src="/images/pixelt.png" alt="" width="1" height={heightAval} border="0"/>
            </div>
            <Footer />
          </Container>
        )}
        {!session.isLoggedIn && (
          <Spinner animation="border" style={{color: "white"}}></Spinner>
        )}
    </div>
    </LoadingOverlay>
    </LoadingOverlay>
    );
  }
}

export default connect(mapStateToProps)(Download)