import { download_metrics, update_tag, insert_new_tag } from "../../state/actions/Admin.actions";

export const downloadMetrics = (url, apk) =>
dispatch => {
    dispatch(download_metrics(url, apk));
}

export const updateTag = (url, apk, json) =>
dispatch => {
    dispatch(update_tag(url, apk, json));
}

export const insertNewTag = (url, apk, json) =>
dispatch => {
    dispatch(insert_new_tag(url, apk, json));
}