import { api_call } from "../../core/api/api.actions";
import { LOGIN_DONE, SET_LOGGEDIN_TO_FALSE, SET_LOGIN_DATA } from "../types/Login.types";

export const do_login = data => dispatch => {
  return dispatch(
    api_call(process.env.REACT_APP_PISP_AUTHENTICATION_EP, {
      method: "POST",
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_PISP_LOGIN_OCP_APIM_HEADER,
        "Accept-Language": "es-ES"
      },
      data: data
    })
  )
    .then(loginResponse => dispatch(login_done(loginResponse)))
    .catch(() => dispatch(login_done({})));
};

export const login_done = loginResponse  => dispatch => dispatch({
  type: LOGIN_DONE,
  payload: loginResponse
});

export const set_loggedIn_to_false = () => dispatch =>  dispatch({
  type: SET_LOGGEDIN_TO_FALSE
});

export const set_login_data = data => dispatch => dispatch({ type: SET_LOGIN_DATA, payload: data });

export default {
  doLogin: do_login,
  resetLoggedIn: set_loggedIn_to_false
};
