import { api_call } from '../../core/api/api.actions';
import * as types from '../types/Download.types';
//import appConfig from '../../config/app-config.json';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';

export const set_filenames = (fileNames) => ({
  type: types.SET_FILE_NAMES,
  payload: { fileNames },
});
     
export const set_loading_table = (loadingTable) => ({
  type: types.SET_LOADING_VALUE,
  payload: { loadingTable },
});
     
export const set_message = (message) => ({
  type: types.SET_LOADING_VALUE,
  payload: { message },
});

export const set_downloading_files = (downloadingFile) => ({
  type: types.SET_DOWNLOADING_FILES_VALUE,
  payload: { downloadingFile },
});

export const set_deleting_files = (deletingFile) => ({
  type: types.SET_DELETING_FILES_VALUE,
  payload: { deletingFile },
});

export const get_filenames = (url, apK) => dispatch => {
  //const appConfigEnt = appConfig[environment];
  dispatch(set_loading_table(true));
  return dispatch(
    api_call(url, {
      method: 'GET',
      responseType: 'json',
      headers: {
        Authorization: 'validToken',
        'Content-Type': 'application/json',
        'x-api-key': apK,
      },
    }),
  ).then(fileNames => {
    dispatch(set_filenames(fileNames))
    dispatch(set_loading_table(false));
    }
  ).catch(err => {
    console.log(err);
    dispatch(set_loading_table(false));
    dispatch(set_message({ type: "error", msg: "Something was wrong. Please, try again!"}));
    dispatch(set_message({}));
});
}

// 25/08/2020 - FIX 4 - juan.d.martin.garcia
// After a search with files in Download window, 
// we change to another one and then we return to Download window again. 
// Result table is filled with the previous search. We must set empty de array.
export const set_empty_filenames = () => dispatch => {
  return dispatch(set_filenames(null));
}

export function getCurrentDate(){
  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let hour = newDate.getHours();
  let minute = newDate.getMinutes();
  let second = newDate.getSeconds();
  
  return `${year}${month<10?`0${month}`:`${month}`}${date}${hour}${minute}${second}`
}

export const download_filenames = (url, apK, doZip) => dispatch => {
  //const appConfigEnt = appConfig[environment];
  dispatch(set_downloading_files(true));
  return dispatch(
    api_call(url, {
      method: 'GET',
      responseType: 'json',
      headers: {
        Authorization: 'validToken',
        'Content-Type': 'application/json',
        'x-api-key': apK
      },
    }),
  ).then(filesToDownload => {
    let zip = new JSZip();
    let count = 0;
    let zipFilename = "ATCInventory_" + getCurrentDate() + ".zip";

    filesToDownload.forEach(file => {
      let downloadUrl = file.downloadUrl;
      let filename = file.idFile;

      // If There is only one file, DO NOT DO A ZIP!!!
      if (doZip) {
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(downloadUrl, function (err, data) {
        if(err) {
          dispatch(set_message({ type: "error", msg: "Something was wrong. Please, try again!"}));
          dispatch(set_message({}));
          dispatch(set_downloading_files(false));
        }
        zip.file(filename, data, {binary:true});
        count++;
        if (count === filesToDownload.length ) {
          zip.generateAsync({type:"blob"})
          .then(function (blob) {
            saveAs(blob, zipFilename);
            // Success
            dispatch(set_message({ type: "success", msg: "Downloading file(s)…"}));
            dispatch(set_message({})); 
            dispatch(set_downloading_files(false));
          });
        }
      });
      } else {
        window.location.replace(downloadUrl);
        // Success
        dispatch(set_message({ type: "success", msg: "Downloading file(s)…"}));
        dispatch(set_message({})); 
        dispatch(set_downloading_files(false));
      }
    });
    }
  ).catch(err => {
    console.log(err);
    dispatch(set_message({ type: "error", msg: "Something was wrong. Please, try again!"}));
    dispatch(set_message({}));
    dispatch(set_downloading_files(false));
  });
}

export const delete_filenames = (url, apK, urlRefresh) => dispatch => {
  //const appConfigEnt = appConfig[environment];
  dispatch(set_deleting_files(true));
  return dispatch(
    api_call(url, {
      method: 'DELETE',
      responseType: 'json',
      headers: {
        Authorization: 'validToken',
        'Content-Type': 'application/json',
        "x-api-key": apK,
      },
    }),
  ).then(filesDeleted => {
      // Success
      dispatch(set_message({ type: "success", msg: "File(s) and data deleted(s)…"}));
      dispatch(set_message({})); 
      dispatch(set_filenames(null));
      dispatch(get_filenames(urlRefresh, apK));
      dispatch(set_deleting_files(false));
    }
  ).catch(err => {
    console.log(err);
    dispatch(set_message({ type: "error", msg: "Something was wrong. Please, try again!"}));
    dispatch(set_message({}));
    dispatch(set_deleting_files(false));
  });
}