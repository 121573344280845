import React from "react";
import { Route, Router, Switch } from 'react-router-dom';
import { Download, Upload, Home, Administration } from "../../containers";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import Callback from '../../containers/Home/Callback';
import { createBrowserHistory } from 'history'
import withConfiguration from '../../core/configuration/withConfigurationHOC';
import SessionTimeout from "../../components/TimeOut/SessionTimeout";

const history = createBrowserHistory()
  const App = () => (
    <div className="App">
      <SessionTimeout />
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/home" component={Home}/>
          <Route exact path="/callback" component={Callback}/>
          <Route path="/download" component={Download} />
          <Route path="/upload" component={Upload} />
          <Route path="/administration" component={Administration} />
          <Route component={Home} />
        </Switch>
      </Router>
      <ToastContainer position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover />
    </div>
      )
export default withConfiguration(App);