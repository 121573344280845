import React from "react";
import "./Calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class Calendar extends React.Component {
  state = {
    startDate: new Date()
  };

  handleDate(date) {
    this.setState({
      startDate: date
    });
    this.props.handleDate(date);
  }

  render() {
    return (
      <DatePicker
        id="calendar"
        selected={this.state.startDate}
        onChange={date => this.handleDate(date)}
      />
    );
  }
}

export default Calendar;
