import React from 'react';
import axios from 'axios';

const API_HOST = '';

class Fetch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: false,
            error: null
        };
    }


    fetchData = async() => {
        this.setState({ loading: true });
        let cacheBuster = new Date().getTime();
        let urlCombo=`${API_HOST}${this.props.path}${this.props.options}.txt?cacheBuster=${cacheBuster}`;
        let params = {
            params: { nombre: this.props.options }
        };

        //CALL to TXT
        await axios.get(urlCombo, params)
        .then(r => {
            let data = r.data;
            if ((r.status = 200)) {
                // data contains an Array of Objects. We return values
                // 10/06/2020 - FIX 1 - juan.d.martin.garcia
                // As the display is now performed by Jenkins, upload the files
                // txt of the combos in UNIX LF format and NOT WINDOWS CR LF. Therefore the split
                // we will do it with \ n and the txt files will be in UNIX LF format
                let comboList = data.split(/\n/);
                data=comboList;
                this.setState({ data, loading: false});
            }}
        ).catch(
            e => {
                this.setState({ e, loading: false});
                console.log(e);
            }
        );
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        return this.props.children(this.state);
    }
}

export default Fetch;
