import React from "react";
import "./Upload.css";
import { connect } from 'react-redux'
import { Form } from "../../components";
import { Container } from 'react-bootstrap';
import Spinner from "react-bootstrap/Spinner";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import LoadingOverlay from 'react-loading-overlay';

const mapStateToProps = state => {
  return { session: state.session, uploadingFile: state.upload.uploadingFile, }
}

class Upload extends React.Component {
  state = {
    uploadingFile: false
  };

  componentDidMount () {
    // If it's not logged in, redirect to Home
    if (!this.props.session.isLoggedIn) window.location.replace("/");
}

  render() {
    const { session, } = this.props;
    const { uploadingFile } = this.props;
    LoadingOverlay.propTypes = undefined;
    return (
      <LoadingOverlay active={uploadingFile} text={"Uploading document..."} spinner>
      <div className="upload">
        {session.isLoggedIn && (
          <Container className="container-lg" style={{"maxWidth": "85%"}}>
            <Header />
            <Form />
            <Footer />
          </Container>
        )}
        {!session.isLoggedIn && (
          <Spinner animation="border" style={{color: "white"}}></Spinner>
        )}
    </div>
    </LoadingOverlay>
    );
  }
}

export default connect(mapStateToProps)(Upload)
