import { upload_filename, set_message } from "../../state/actions/Upload.actions";

export const uploadFilename = (url, environment, apK, json, file) =>
dispatch => {
    dispatch(upload_filename(url, environment, apK, json, file));
}

export const setMessage = () =>
dispatch => {
    dispatch(set_message({}));
}