import * as types from '../types/Download.types';

const initialState = {
  filenames: [],
  loadingTable: false,
  downloadingFile: false,
  deletingFile: false,
  message: {},
};

const download = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
        case types.SET_FILE_NAMES:
        return {...state, ...payload};
        case types.SET_LOADING_VALUE:
        return {...state, ...payload};
        case types.SET_DOWNLOADING_FILES_VALUE:
        return {...state, ...payload};
        case types.SET_DELETING_FILES_VALUE:
          return {...state, ...payload};
        case types.SET_MESSAGE:
        return {...state, ...payload};
    default:
      return state;
  }
};

export default download;
